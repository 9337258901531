<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="monthCard-edit">
            <el-form :model="form" ref="form" :rules="rules" label-width="145px" label-position="left">
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin-bottom: 16px">
                    <span>基本信息</span>
                    <el-popover
                        placement="bottom-start"
                        title=""
                        width="400"
                        trigger="hover">
                        <div class="a-flex-cfsfs">
                            <span><b>有效天数：</b>指用户购买后套餐实际可以使用的天数</span>
                            <span><b>卡组：</b>同一个卡组可以认为是一种类型的套餐，同一卡组的套餐购买后可以叠加延期</span>
                            <span><b>可见模式：</b>不同设备种类可以看到套餐的展示情况</span>
                            <span><b>关联车辆型号：</b>这里是控制用户选择不同车辆后展示套餐情况 如某套餐关联了 电瓶车48v 那么只有车辆是48v电瓶车的用户可以看到这个套餐</span>
                        </div>
                        <span slot="reference" class="el-icon-info a-c-333" style="margin-left: 16px;font-size: 18px;"></span>
                    </el-popover>
                </div>
                <el-row :gutter="72">
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name" >
                            <el-input v-model="form.name" maxlength="20" style="width:240px" placeholder="请输入名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否系统默认卡" prop="isSystemDefaultCard">
                            <el-select v-model="form.isSystemDefaultCard" placeholder="请选择" clearable>
                                <el-option v-for="item in isSystemDefaultDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72">
                    <el-col :span="12">
                        <el-form-item label="卡片类型" prop="type">
                            <el-select v-model="form.type" placeholder="请选择" clearable @change="cardTypeChange">
                                <el-option v-for="item in cardTypeDic"
                                    :disabled="form.isTempCard == 6 && item.value != 4"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <!-- <el-form-item label="卡片种类" prop="isTempCard">
                            <el-select v-model="form.isTempCard" placeholder="请选择" clearable @change="cardCategeryChange">
                                <el-option v-for="item in isTempcardDic"
                                    :disabled="((form.type==1||form.type==3) && item.value == 1) || (form.type==4 && item.value != 6)"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="卡片种类" prop="isTempCard">
                            <el-select v-model="form.isTempCard" placeholder="请选择" clearable @change="cardCategeryChange">
                                <el-option label="临时卡" value="1"></el-option>
                                <el-option label="套餐卡" value="99"></el-option>
                                <el-option label="储值卡" value="6"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="form.isTempCard == 99">
                    <el-col :span="24">
                        <el-form-item label="套餐卡规格" class="packageSku">
                            <el-switch
                                v-model="isAiSetParams"
                                :active-value="1"
                                :inactive-value="0"
                                active-text="智能调价"
                                active-color="#007AFF"
                                inactive-color="#F56C6C">
                            </el-switch>
                            <el-table
                                ref="multipleTable"
                                :border='true'
                                @selection-change="handleSelectionChange"
                                :data="tableData"
                                style="width: 100%;">
                                <el-table-column type="selection" fixed="left" width="45" ></el-table-column>
                                <el-table-column
                                    label="类型">
                                    <template slot-scope="{ row }">
                                        <span>{{ row.isTempCard | initDic(isTempcardDic) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="有效天数">
                                    <template slot-scope="{ row }">
                                        <el-input style="max-width:240px" maxlength="6" v-model="row.validity" @input="row.validity=row.validity.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="实际售价">
                                    <template slot-scope="{ row }">
                                        <jd-input-price :disabled='isAiSetParams && row.isTempCard != 2' v-model="row.promotionPrice" @input="skuInputPromotion('promotionPrice')" style="max-width:240px"></jd-input-price>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="划线价格">
                                    <template slot-scope="{ row }">
                                        <jd-input-price :disabled='isAiSetParams && row.isTempCard != 2' v-model="row.price" @input="skuInputPromotion('price')" style="max-width:240px"></jd-input-price>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="可见模式" v-if="editType != 'addTemp'">
                                    <template slot-scope="{ row }">
                                        <el-select v-model="row.showMode" placeholder="请选择" clearable>
                                            <el-option v-for="item in showModelDic"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="关联活动">
                                    <template slot-scope="scope">
                                        <div class="a-flex-rfsfs">
                                            <span class="stationTag" v-if="scope.row.activity">
                                                {{ scope.row.activity.activityTitle?scope.row.activity.activityTitle:'-' }}
                                                {{ scope.row.activity.startTime?scope.row.activity.startTime:'-' }} 至 
                                                {{ scope.row.activity.endTime?scope.row.activity.endTime:'-' }}
                                                <i class="el-icon-error a-fs-14" @click="removeActivity(scope.$index)"></i>
                                            </span>
                                            <i class="el-icon-circle-plus-outline a-fs-24 a-c-red" @click="chooseActivity(scope.$index)"></i>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72" v-if="form.isTempCard != 99">
                    <el-col :span="12">
                        <el-form-item label="划线价" prop="price" >
                            <jd-input-price v-model="form.price" style="width:240px"></jd-input-price>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实际售价" prop="promotionPrice" >
                            <jd-input-price v-model="form.promotionPrice" style="width:240px"></jd-input-price>
                        </el-form-item>
                    </el-col>
                </el-row>
                
                <el-row :gutter="72">
                    <el-col :span="24">
                        <el-form-item label="支付方式" prop="payWay">
                            <div class="a-flex-rfsc">
                                <el-radio-group v-model="form.payWay" style="height: 32px" class="a-flex-rfsc">
                                    <el-radio 
                                        :disabled="(form.isTempCard != 1 && item.value == 3) || item.value == 2" 
                                        :label="item.value" 
                                        v-for="(item,index) in payWayDic" 
                                        :key="index">{{ item.text }}</el-radio>
                                </el-radio-group>
                                <span style="margin: 0 10px" v-if="form.payWay == 3">，费用上限</span>
                                <jd-input-price v-model="form.maxPrice" v-if="form.payWay == 3"></jd-input-price>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72">
                    <el-col :span="12">
                        <el-form-item label="充值赠送" prop="giftAmount" v-if="form.isTempCard == 6">
                            <jd-input-price v-model="form.giftAmount"></jd-input-price>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72">
                    <el-col :span="12">
                        <el-form-item label="促销描述" prop="promotionDescription" >
                            <el-input style="width:240px" v-model="form.promotionDescription" maxlength="20" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="适用范围描述" prop="applicableDescription" >
                            <el-input style="width:240px" v-model="form.applicableDescription" maxlength="20" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72">
                    <el-col :span="12">
                        <el-form-item :label="form.isTempCard == 1?'有效时长(小时)':'有效天数(天)'" prop="validity" v-if="form.isTempCard != 99">
                            <!-- <jd-input-intnum v-model="form.validity"></jd-input-intnum> -->
                            <el-input style="width:240px" maxlength="6" v-model="form.validity" @input="form.validity=form.validity.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.isTempCard != 99">
                        <el-form-item label="可见模式" prop="showMode" >
                            <el-select v-model="form.showMode" placeholder="请选择" clearable>
                                <el-option v-for="item in showModelDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72">
                    <el-col :span="12">
                        <el-form-item label="激活模式" prop="activationMode" >
                            <el-select v-model="form.activationMode" placeholder="请选择" clearable>
                                <el-option v-for="item in activeCardDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <!-- <el-form-item label="是否需要邮寄" prop="cardNeedPost" >
                            <el-select v-model="form.cardNeedPost" placeholder="请选择" clearable>
                                <el-option v-for="item in needPostDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="实体卡费用" prop="cardPrice" >
                            <jd-input-price v-model="form.cardPrice" style="width:240px"></jd-input-price>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72">
                    <el-col :span="12">
                        <el-form-item label="缩略图" prop="icon" >
                            <le-upload-file-img notnull ref="icon" label="" :limit="1" v-model="form.icon"></le-upload-file-img>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.type == 1 || form.type == 3">
                        <el-form-item label="单日充电时长(分钟)" prop="quotaDuration" >
                            <jd-input-intnum v-model="form.quotaDuration" placeholder="输入-1则不限制" style="width:240px"></jd-input-intnum>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="(form.type == 2 || form.type == 3)">
                        <el-form-item label="远程单日开门次数" prop="parkTimes" >
                            <jd-input-intnum v-model="form.parkTimes" placeholder="输入-1则不限制次数" style="width:240px"></jd-input-intnum>
                        </el-form-item>
                    </el-col>
                    
                    
                </el-row>
                <el-row :gutter="72">
                    <el-col :span="24" v-if="editType != 'addTemp'">
                        <el-form-item label="开售/失效时间" prop="enableTime" >
                            <jd-datetime-range :large="true" style="margin:0;padding: 0;" label="" valueFormat="timestamp" :minDate.sync="form.enableTime" :maxDate.sync="form.expireTime" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="chargingExpireType">
                            <div class="a-flex-rfsc" slot="label">
                                <span>资源类型</span>
                                <el-popover
                                    placement="bottom-start"
                                    title=""
                                    width="400"
                                    trigger="hover">
                                    <div class="a-flex-cfsfs">
                                        <span><b>总量递减型：</b>按实际消费进行抵扣，直到余额清零或者过期。</span>
                                        <span><b>自然月周期型：</b>每月都有固定额度，次月1号恢复额度，当月剩余不可转移到下月。</span>
                                    </div>
                                    <span slot="reference" class="el-icon-info a-c-333" style="margin-left: 16px;font-size: 16px;"></span>
                                </el-popover>
                            </div>
                            <el-radio-group v-model="form.chargingExpireType" @change="chargingExpireTypeChange">
                                <el-radio :label="item.value" v-for="(item,index) in chargingExpireTypeDic" :key="index">{{ item.text }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="form.isTempCard != 99">
                        <el-form-item label="关联活动" prop="activity" >
                            <el-button size="mini" icon="el-icon-plus" type="primary" @click="chooseActivity">选择活动</el-button>
                            <div class="a-flex-rfsc a-flex-wrap" style="margin-top: 10px;" v-if="form.activity">
                                <span class="stationTag">
                                    {{ form.activity.activityTitle?form.activity.activityTitle:'-' }}
                                    {{ scope.row.activity.startTime?scope.row.activity.startTime:'-' }} 至 
                                    {{ scope.row.activity.endTime?scope.row.activity.endTime:'-' }}
                                    <i class="el-icon-error a-fs-14" @click="removeActivity"></i>
                                </span>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="72" v-if="editType != 'addTemp'">
                    <el-col :span="24">
                        <el-form-item label="项目" prop="statioinIdList" >
                            <div class="a-flex-rfsfs">
                                <le-select-remote-search 
                                    class="projectBox"
                                    style="padding:0" 
                                    v-model="form.incomeDistriId" 
                                    :options="optionsincomeDistri" 
                                    placeholder="请选择项目(可搜索)" />
                                <le-select-remote-search 
                                    style="padding:0" 
                                    :multiple="true" 
                                    v-model="form.statioinIdList" 
                                    :options="optionsStatioinIdList" 
                                    placeholder="请选择站点(可搜索)" />
                                <!-- <el-select v-model="form.cardGroupId" placeholder="请选择" style="width:240px;margin-left: 20px;">
                                    <div slot="empty" class="a-flex-rfsc addBankBtn" @click="addCardGroup">
                                        <i class="el-icon-plus a-fw-700"></i>
                                        <span class="a-plr-7">添加卡组</span>
                                    </div>
                                    <div class="a-flex-rfsc addBankBtn" @click="addCardGroup">
                                        <i class="el-icon-plus a-fw-700"></i>
                                        <span class="a-plr-7">添加卡组</span>
                                    </div>
                                    <el-option 
                                        v-for="(item) in cardGroup"
                                        :key="item.id"
                                        :label="item.groupName" 
                                        :value="item.id"></el-option>
                                </el-select> -->
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                
                <el-row :gutter="72" v-if="form.type != 4">
                    <el-col :span="24">
                        <el-form-item label="关联车辆型号显示" prop="bikeTypeIdList" >
                            <jd-select-muti :getCityData="getCityData" :checkDatas="checkDatas"></jd-select-muti>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <le-activity-choose :multiple="false" :editType='editType' ref="chooseActivity" :defaultData="form.activity" @selectChange="getActivity"></le-activity-choose>
    </div>
</template>

<script>
import jdSelectMuti from '../components/formcy/jd-select-muti.vue'
import dayjs from 'dayjs'
import LeActivityChoose from '../components/choose/le-activity-choose.vue';
export default {
    components:{
        jdSelectMuti,
        LeActivityChoose
    },
    data() {
        var checkNoLimit = (rule, value, callback) => {
            let tag = value[0]
            if(tag == '-'){
                if(value=='-1'){
                    callback()
                }else{
                    callback(new Error('输入值非法，请输入-1或正整数'))
                }
            }else if(value==0){
                callback(new Error('输入值非法，请输入-1或正整数'))
            }else{
                callback()
            }
        };
        var checkEnableTime = (rule, value, callback) => {
            let nowDate = new Date()
            if(value<nowDate){
                callback(new Error('开售时间不得小于当前时间'))
            }else{
                callback()
            }
        };
        var checkCardGroup = (rule, value, callback) => {
            if(!this.form.cardGroupId){
                callback(new Error('请选择卡组'))
            }else{
                callback()
            }
        };
        var checkPayWay = (rule, value, callback) => {
            if(this.form.payWay == 3 && !this.form.maxPrice){
                callback(new Error('请输入费用上限'))
            }else{
                callback()
            }
        };
        return {
            id: '',//月卡id 编辑用
            groupId: '',//月卡组id
            form:{
                name: "",//名称(月卡/季卡/年卡/时长卡/次卡)
                icon: '',//头像
                type: '',//月卡类型 1:充电卡 2:停车卡 3:停车充电卡
                isTempCard: '',//是否是临时卡 1:是 0:否
                isSystemDefaultCard: '0',//是否是系统默认卡 1:是 0:否
                incomeDistriId: '',//项目ID 月卡配置必须与项目挂钩
                validity: '',//有效期(天)
                price: '',//原价(划线价,对比价,单位:分)
                promotionPrice: '',//促销价(实际支付价格,单位:分)
                promotionDescription: "",//促销描述
                quotaTimes: '',//充电次数限制
                quotaDuration: '',//充电时长限制(单位:分)
                parkTimes: '',//停车限制次数 0:不限制
                applicableDescription: "",//适用范围描述
                activationMode: '',//激活模式(0:立即激活,1:首次使用激活)
                showMode: '',//1:全部可见 2:充电可见 3:停车可见
                cardNeedPost: '',//卡片是否需要邮寄 1:需要 0:不需要
                cardPrice: '',//实体卡费用
                enableTime: '',//开售时间
                expireTime: '',//失效时间
                quotaTimeType: '',//充电时间类型
                parkTimeType: '',//停车时间限制
                quotaLimitType: '',// 充电次数限制类型
                parkLimitType: '',// 开门次数限制类型
                bikeTypeIdList: [],//
                statioinIdList: [],//
                cardGroupId: '',//卡组id
                giftAmount: '',//储值卡充值赠送金额
                activity: null,//关联活动
                activityList: null,//关联活动
                payWay: '1',
                maxPrice: '',//临停后付金额上限
                chargingExpireType: '1',
            },
            rules:{
                name: [{required: true, message:'请输入名称', trigger: 'blur'}],//名称(月卡/季卡/年卡/时长卡/次卡)
                icon: [{required: true, message:'请上传缩略图', trigger: 'change'}],//头像
                type: [{required: true, message:'请选择', trigger: 'change'}],//月卡类型 1:充电卡 2:停车卡 3:停车充电卡
                isTempCard: [{required: true, message:'请选择', trigger: 'change'}],//是否是临时卡 1:是 0:否
                isSystemDefaultCard: [{required: true, message:'请选择', trigger: 'change'}],//是否是系统默认卡 1:是 0:否
                incomeDistriId: '',//项目ID 月卡配置必须与项目挂钩
                validity: [{required: true, message:'请输入有效天数', trigger: 'blur'}],//有效期(天)
                price: [{required: true, message:'请输入划线价', trigger: 'blur'}],//原价(划线价,对比价,单位:分)
                promotionPrice: [{required: true, message:'请输入促销价', trigger: 'blur'}],//促销价(实际支付价格,单位:分)
                promotionDescription: [{required: true, message:'请输入促销描述', trigger: 'blur'}],//促销描述
                quotaTimes: [{required: true, message:'请输入充电次数限制', trigger: 'blur'}],//充电次数限制
                quotaDuration: [{required: true, message:'请输入充电时长限制', trigger: 'blur'}, { validator: checkNoLimit, trigger: 'blur' }],//充电时长限制(单位:分)
                parkTimes: [{required: true, message:'请输入停车次数限制', trigger: 'blur'}, { validator: checkNoLimit, trigger: 'blur' }],//停车限制次数 -1:不限制
                // parkMinutes: '',//停车限制时长 0:不限制
                applicableDescription: [{required: true, message:'请输入适用范围描述', trigger: 'blur'}],//适用范围描述
                activationMode: [{required: true, message:'请选择', trigger: 'change'}],//激活模式(0:立即激活,1:首次使用激活)
                showMode: [{required: true, message:'请选择', trigger: 'change'}],//1:全部可见 2:充电可见 3:停车可见
                // cardNeedPost: '',//卡片是否需要邮寄 1:需要 0:不需要
                enableTime: [{required: true, message:'请选择时间', trigger: 'change'}],//开售时间
                cardPrice: [{required: true, message:'请填写实体卡费用', trigger: 'blur'}],//开售时间
                giftAmount: [{required: true, message:'请填写充值赠送金额', trigger: 'blur'}],//giftMoney
                quotaTimeType: '',//充电时间类型
                parkTimeType: '',//停车时间类型
                quotaLimitType: '',// 充电次数限制类型
                parkLimitType: '',// 开门次数限制类型
                bikeTypeIdList: [{ type: 'array', required: true, message: '请至少选择一个属性', trigger: 'change' }],//
                statioinIdList: [{ type: 'array', required: true, message: '请至少选择一个站点', trigger: 'change' }],//
                payWay: [{ validator: checkPayWay, trigger: 'change' }],
            },
            cardTypeDic: [],//卡片类型字典
            isTempcardDic: [],//是否临时卡字典
            isSystemDefaultDic: [],//是否系统默认卡字典
            activeCardDic: [],//激活模式字典
            needPostDic: [],//是否邮寄
            showModelDic: [],//可见模式字典
            timeTypeDic: [],//时间类型字典
            chargingExpireTypeDic: [],
            optionsincomeDistri: {
                url: this.$Config.apiUrl.incomeDistriList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },//项目数据
            optionsStatioinIdList: {
                url: this.$Config.apiUrl.monthStationList,
                method: "post",
                params: {
					incomeDistriId: ''
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },//站点数据
            monthcardTimeLimitDic: [],//充电开门次数限制类型
            setTransfer: '',//设置穿梭框的初始值
            cardGroup: [],// 卡组列表
            payWayDic: [],// 支付类型字典
            isAiSetParams: 1,//是否智能调价
            tableData: [],//套餐卡规格数据
            selectTableData: [],//选中的套餐卡规格数据
            currentSkuIndex: [],//当前选中的套餐卡规格index
            editType: '',//编辑类型，addTemp--向套餐模板组内添加套餐
        };
    },
    created () {
        this.$getDic('monthcardType','select').then(res=>{ this.cardTypeDic = res; })
        this.$getDic('istempcard','select').then(res=>{ 
            this.isTempcardDic = res; 
            let arr = []
            this.isTempcardDic.map(item=>{
                if(item.value != 1 && item.value != 6) {
                    arr.push({
                        id: '',
                        isTempCard: item.value,
                        validity: '',
                        promotionPrice: 30,
                        price: 40,
                        activity: null,
                        showMode: '1'
                    })
                }
            })
            this.tableData = arr
            this.skuInputPromotion('promotionPrice')
            this.skuInputPromotion('price')
        })
        this.$getDic('isSystemDefault','select').then(res=>{ this.isSystemDefaultDic = res; })
        this.$getDic('activeCard','select').then(res=>{ this.activeCardDic = res; })
        this.$getDic('needPost','select').then(res=>{ this.needPostDic = res; })
        this.$getDic('showModel','select').then(res=>{ this.showModelDic = res; })
        this.$getDic('timeType','select').then(res=>{ this.timeTypeDic = res; })
        this.$getDic('payWay').then(res=>{ this.payWayDic = res; })
        this.$getDic('chargingExpireType').then(res=>{ this.chargingExpireTypeDic = res; })
        this.$getDic('monthcardTimeLimit','select').then(res=>{ 
            this.monthcardTimeLimitDic = res;
            this.form.quotaLimitType = this.monthcardTimeLimitDic[0].value 
            this.form.parkLimitType = this.monthcardTimeLimitDic[0].value
        })
    },
    computed: {
        
    },
    watch:{
        'form.incomeDistriId' :{
            handler (val) {
                this.optionsStatioinIdList.params.incomeDistriId = val
                this.form.statioinIdList = []
                this.getCardGroupList()
            }
        }
    },
    mounted () {
        this.form.expireTime = dayjs().add(20, 'year')
        this.form.enableTime = dayjs()
        this.id = this.$route.query.id
        this.groupId = this.$route.query.groupId
        this.editType = this.$route.query.type
        if(this.id) {
            this.getMonthCardInfo()
        }
        if(this.groupId) {
            this.getMonthCardGroupInfo()
        }
    },
    methods: {
        getMonthCardInfo () {
            return
            this.$Axios._get({
                url: this.$Config.apiUrl.monthCardDdetails,
                method: "get",
                params: {
                    id: this.id
                }
            }).then(res => {
                if (res.result.code == 0) {
                    let statioinIdList = res.result.data.statioinIdList
                    this.form = {
                        ...res.result.data,
                    }
                    this.form.type = JSON.stringify(this.form.type)
                    this.form.isTempCard = JSON.stringify(this.form.isTempCard)
                    this.form.isSystemDefaultCard = JSON.stringify(this.form.isSystemDefaultCard)
                    this.form.showMode = JSON.stringify(this.form.showMode)
                    this.form.activationMode = JSON.stringify(this.form.activationMode)
                    this.form.cardNeedPost = JSON.stringify(this.form.cardNeedPost)
                    this.form.quotaLimitType = JSON.stringify(this.form.quotaLimitType)
                    this.form.parkLimitType = JSON.stringify(this.form.parkLimitType)
                    this.form.quotaTimeType = JSON.stringify(this.form.quotaTimeType)
                    this.form.parkTimeType = JSON.stringify(this.form.parkTimeType)
                    this.form.payWay = JSON.stringify(this.form.payWay)
                    this.form.chargingExpireType = JSON.stringify(this.form.chargingExpireType)
                    this.form.promotionPrice = this.form.promotionPrice?(this.form.promotionPrice/100).toFixed(2):0
                    this.form.price = this.form.price?(this.form.price/100).toFixed(2):0
                    this.form.cardPrice = this.form.cardPrice?(this.form.cardPrice/100).toFixed(2):0
                    this.form.giftAmount = this.form.giftAmount?(this.form.giftAmount/100).toFixed(2):0
                    this.form.maxPrice = this.form.maxPrice?(this.form.maxPrice/100).toFixed(2):0
                    this.form.cardGroupId = this.form.cardGroupId
                    this.form.activity = this.form.activityList&&this.form.activityList.length?this.form.activityList[0]:null
                    this.$nextTick(()=>{
                        this.form.statioinIdList = statioinIdList.map(item=>{
                            return item.code
                        })
                        if(this.setTransfer) this.setTransfer(this.form.bikeTypeIdList)
                    })
                    
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        getMonthCardGroupInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.monthCardGroupDetails,
                method: "post",
                params: {
                    groupId: this.groupId
                }
            }).then(res => {
                if (res.result.code == 0) {
                    let monthcardType = [2,3,4,5] //月卡种类 月卡，季卡，半年卡，年卡
                    let arr = []
                    res.result.data.detailsList.map(item=>{
                        this.tableData.map((ite,idx)=>{
                            if(item.isTempCard == ite.isTempCard){
                                this.tableData[idx] = {
                                    id: item.id,
                                    isTempCard: item.isTempCard,
                                    validity: item.validity,
                                    promotionPrice: Number((item.promotionPrice/100).toFixed(2)),
                                    price: Number((item.price/100).toFixed(2)),
                                    activity: item.activityList&&item.activityList.length?item.activityList[0]:null,
                                    showMode: JSON.stringify(item.showMode)
                                }
                                if(item.activityList&&item.activityList.length){
                                    this.tableData[idx].activity.startTime = this.tableData[idx].activity.goodsStartTime
                                    this.tableData[idx].activity.endTime = this.tableData[idx].activity.goodsEndTime
                                }
                                arr.push(this.tableData[idx])
                                this.$refs['multipleTable'].toggleRowSelection(this.tableData[idx],true)
                            }
                        })
                    })
                    this.selectTableData = arr
                    let statioinIdList = res.result.data.detailsList[0].statioinIdList
                    this.form = {
                        ...res.result.data.detailsList[0],
                    }
                    this.form.type = JSON.stringify(this.form.type)
                    this.form.isTempCard = this.selectTableData.length?'99':JSON.stringify(this.form.isTempCard)
                    this.form.isSystemDefaultCard = JSON.stringify(this.form.isSystemDefaultCard)
                    this.form.showMode = JSON.stringify(this.form.showMode)
                    this.form.activationMode = JSON.stringify(this.form.activationMode)
                    this.form.cardNeedPost = JSON.stringify(this.form.cardNeedPost)
                    this.form.quotaLimitType = JSON.stringify(this.form.quotaLimitType)
                    this.form.parkLimitType = JSON.stringify(this.form.parkLimitType)
                    this.form.quotaTimeType = JSON.stringify(this.form.quotaTimeType)
                    this.form.parkTimeType = JSON.stringify(this.form.parkTimeType)
                    this.form.payWay = JSON.stringify(this.form.payWay)
                    this.form.chargingExpireType = JSON.stringify(this.form.chargingExpireType)
                    this.form.promotionPrice = this.form.promotionPrice?(this.form.promotionPrice/100).toFixed(2):0
                    this.form.price = this.form.price?(this.form.price/100).toFixed(2):0
                    this.form.cardPrice = this.form.cardPrice?(this.form.cardPrice/100).toFixed(2):0
                    this.form.giftAmount = this.form.giftAmount?(this.form.giftAmount/100).toFixed(2):0
                    this.form.maxPrice = this.form.maxPrice?(this.form.maxPrice/100).toFixed(2):0
                    this.form.cardGroupId = this.form.cardGroupId
                    this.form.activity = this.form.activityList&&this.form.activityList.length?this.form.activityList[0]:null
                    if(this.form.activityList&&this.form.activityList.length){
                        this.form.activity.startTime = this.form.activity.goodsStartTime
                        this.form.activity.endTime = this.form.activity.goodsEndTime
                    }
                    this.$nextTick(()=>{
                        this.form.statioinIdList = statioinIdList.map(item=>{
                            return item.code
                        })
                        if(this.setTransfer) this.setTransfer(this.form.bikeTypeIdList)
                    })
                    
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 卡片类型改变
        cardTypeChange () {
            this.$refs['form'].clearValidate(['quotaDuration','quotaTimes','parkTimes','bikeTypeIdList'])
            if(this.form.type == 1 || this.form.type == 3){
                this.form.isTempCard = this.form.isTempCard==1?'':this.form.isTempCard
            }
            if(this.form.type == 2 || this.form.type == 3){
                this.$nextTick(()=>{
                    if(this.setTransfer) this.setTransfer(this.form.bikeTypeIdList)
                })
            }
            
        },
        // 卡种类改变
        cardCategeryChange () {
            this.form.payWay = '1'
        },
        // 充电次数限制类型改变
        quotaLimitTypeChange () {
            this.form.quotaTimes = ''
            this.form.quotaTimeType = this.timeTypeDic[0].value
        },
        // 开门次数限制类型改变
        parkLimitTypeChange () {
            this.form.parkTimes = ''
            this.form.parkTimeType = this.timeTypeDic[0].value
        },
        // 资源类型改变
        chargingExpireTypeChange () {
            this.$alert('资源类型修改后，对应卡组下的所有套餐的资源类型都会被改为对应类型，请仔细核对后提交。', '温馨提示', {
                confirmButtonText: '我知道了',
                type: 'warning',
                callback: action => {
                    console.log(action);
                }
            });
        },
        // 获取项目卡组列表
        getCardGroupList () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getCardGroupList,
                method: "post",
                params: {
                    incomedistriId: this.form.incomeDistriId,
                    groupName: ''
                }
            })
            .then(res=>{
                if (res.result.code == 0) {
                    this.cardGroup = res.result.data
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{
                this.cardGroup = []
            })
        },
        // 获取穿梭框返回值
        getCityData (e) {
            this.form.bikeTypeIdList = e
            this.$refs['form'].validateField('bikeTypeIdList')
        },
        checkDatas (fun) {
            this.setTransfer = fun?fun:null
        },
        addCardGroup () {
            if(!this.form.incomeDistriId){
                this.$message.error('请先选择项目')
                return
            }
            this.$prompt('', '添加卡组', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator: (val)=>{
                    if(!val){
                        return '请输入卡组名称'
                    }else if(val.length > 10){
                        return '名称最多输入10字'
                    }
                }
            }).then(({ value }) => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.addCardGroup,
                    method: "post",
                    params: {
                        incomedistriId: this.form.incomeDistriId,
                        groupName: value
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.$message.success('操作成功')
                        this.getCardGroupList()
                    }else{
                        this.$message.error(res.result.message)
                    }
                }).catch(()=>{ })
            }).catch(() => {});
        },
        submit () {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    if(this.form.isTempCard == 99) {
                        let checkResult = await this.checkRules(this.selectTableData)
                        if(!checkResult) {
                            return
                        }
                    }
                    
                    let reqDataArr = []
                    if(this.selectTableData.length) {
                        this.selectTableData.map(item=>{
                            let reqData = {
                                ...this.form,
                                ...item
                            }
                            reqData.promotionPrice = reqData.promotionPrice?Number((reqData.promotionPrice*100).toFixed(0)):0
                            reqData.price = reqData.price?Number((reqData.price*100).toFixed(0)):0
                            reqData.cardPrice = reqData.cardPrice?Number((reqData.cardPrice*100).toFixed(0)):0
                            reqData.giftAmount = reqData.giftAmount?Number((reqData.giftAmount*100).toFixed(0)):0
                            reqData.maxPrice = reqData.maxPrice?Number((reqData.maxPrice*100).toFixed(0)):0
                            reqData.statioinIdList = reqData.statioinIdList.map(item=>{
                                return {
                                    code: item,
                                    name: ''
                                }
                            })
                            // if(this.editType == 'addTemp') {
                            //     reqData.activityList = reqData.activity?[reqData.activity]:[]
                            // }else {
                            //     reqData.activityList = reqData.activity?[reqData.activity.id]:[]
                            // }
                            reqData.activityList = reqData.activity?[reqData.activity]:[]
                            reqDataArr.push(reqData)
                        })
                    }else {
                        let reqData = {
                            ...this.form,
                        }
                        reqData.promotionPrice = reqData.promotionPrice?Number((reqData.promotionPrice*100).toFixed(0)):0
                        reqData.price = reqData.price?Number((reqData.price*100).toFixed(0)):0
                        reqData.cardPrice = reqData.cardPrice?Number((reqData.cardPrice*100).toFixed(0)):0
                        reqData.giftAmount = reqData.giftAmount?Number((reqData.giftAmount*100).toFixed(0)):0
                        reqData.maxPrice = reqData.maxPrice?Number((reqData.maxPrice*100).toFixed(0)):0
                        reqData.statioinIdList = reqData.statioinIdList.map(item=>{
                            return {
                                code: item,
                                name: ''
                            }
                        })
                        if(this.editType == 'addTemp') {
                            reqData.activityList = reqData.activity?[reqData.activity]:[]
                        }else {
                            reqData.activityList = reqData.activity?[reqData.activity.id]:[]
                        }
                        reqDataArr.push(reqData)
                    }
                    
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        if(this.editType == 'addTemp') {
                            // 向模板组内添加套餐
                            this.$eventBus.$emit('addTempInfo',[...reqDataArr])
                            this.$router.back()
                            this.$store.dispatch("delView", this.$route);
                            return
                        }
                        this.$Axios._post({
                            url: this.$Config.apiUrl.addMonthCard,
                            method: "post",
                            params: {
                                validList: [...reqDataArr]
                            }
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })
                    })
                    .catch(_=>{})
                    
                }
            })
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
        // 选择添加活动
        chooseActivity (index) {
            this.currentSkuIndex = index
            this.$refs['chooseActivity'].dialogVisible = true
        },
        getActivity (datas) {
            if(this.form.isTempCard == 99) {
                this.tableData[this.currentSkuIndex].activity = datas
            }else{
                this.form = Object.assign({},{
                    ...this.form,
                    activity: datas
                })
            }
        },
        // 删除选择的活动
        removeActivity (index) {
            // this.$confirm('是否确认删除？','温馨提示')
            // .then(_=>{
            //     // this.form.activity.splice(index,1)
            //     this.form = Object.assign({},{
            //         ...this.form,
            //         activity: null
            //     })
            // })
            // .catch(_=>{})
            if(this.form.isTempCard == 99) {
                this.tableData[index].activity = null
            }else{
                this.form = Object.assign({},{
                    ...this.form,
                    activity: null
                })
            }
        },
        skuInputPromotion (params) {
            if(this.isAiSetParams) {
                let monthcard = this.tableData.find(res=>{
                    return res.isTempCard == 2
                })
                let price = monthcard[params] || 0
                this.tableData.map(item=>{
                    if(item.isTempCard == 3){
                        item[params] = Number((price*3).toFixed(2))
                    }else if(item.isTempCard == 4) {
                        item[params] = Number((price*6).toFixed(2))
                    }else if(item.isTempCard == 5) {
                        item[params] = Number((price*12).toFixed(2))
                    }
                })
            }
        },
        // 套餐规格选中
        handleSelectionChange (selection) {
            this.selectTableData = selection
        },
        checkRules (reqData) {
            return new Promise((resolve, reject) => {
                if(reqData.length && reqData){
                    for (let index = 0; index < reqData.length; index++) {
                        const item = reqData[index];
                        if(!item.validity){
                            this.$message.error('请输入套餐规格的有效天数')
                            resolve(false)
                            return
                        }
                        if(!item.price && item.price !== 0  && item.price !== '0'){
                            this.$message.error('请输入套餐规格的划线价')
                            resolve(false)
                            return
                        }
                        if(!item.promotionPrice && item.promotionPrice !== 0  && item.promotionPrice !== '0'){
                            this.$message.error('请输入套餐规格的实际售价')
                            resolve(false)
                            return
                        }
                    }
                    resolve(true)
                }else{
                    this.$message.error('请至少选择一个套餐规格类型')
                    resolve(false)
                }
                
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.monthCard-edit{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    /deep/ .s-search-label {
		width: 145px !important;
		justify-content: flex-start;
	}
    /deep/ .s-nomarl-input .el-input__inner {
		width: 200px;
	}
    /deep/ .date-range{
        margin: 0;
    }
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
    }
    /deep/ .date-range-large{
        width: 360px;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
.packageSku{
    /deep/ .el-input-group__prepend{
        height: 32px;
        line-height: 32px;
    }
    /deep/ .el-input-group__append{
        height: 32px;
        line-height: 32px;
    }
}

</style>